<template>
  <div class="ad">
    <form method="post" action="https://account.volet.com/sci/" id="volet_form">
      <input type="hidden" name="ac_account_email" :value="ac_account_email" />
      <input type="hidden" name="ac_sci_name" :value="ac_sci_name" />
      <input type="hidden" name="ac_amount" :value="amount" />
      <input type="hidden" name="ac_currency" :value="toUpper(currency)" />
      <input type="hidden" name="ac_order_id" :value="unique_order_id" />
      <!-- Now no need to set ac_sign as it turned off on account settings. -->
      <!-- <input type="hidden" name="ac_sign" :value="ac_sign" /> -->
      <!-- Optional Fields -->
      <input type="hidden" name="ac_success_url" :value="VOLET_SUCCESS_URL" />
      <input type="hidden" name="ac_success_url_method" value="GET" />
      <input type="hidden" name="ac_fail_url" :value="VOLET_CANCEL_URL" />
      <input type="hidden" name="ac_fail_url_method" value="GET" />
      <input type="hidden" name="ac_status_url" :value="VOLET_STATUS_URL" />
      <input type="hidden" name="ac_status_url_method" value="POST" />
      <input
        type="hidden"
        name="ac_comments"
        :value="`Payment order:${transfer_id}`"
      />
      <!-- <input type="submit" /> -->
    </form>

    <Button label="Pay by card" @click="pay()" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      VOLET_SUCCESS_URL: process.env.VUE_APP_SUCCESS_URL,
      VOLET_CANCEL_URL: process.env.VUE_APP_CANCEL_URL,
      VOLET_STATUS_URL: process.env.VUE_APP_STATUS_URL,
    };
  },
  props: [
    'amount',
    'currency',
    'transfer_id',
    'ac_sign',
    'unique_order_id',
    'ac_account_email',
    'ac_sci_name',
    'pay_now',
  ],
  methods: {
    pay() {
      document.getElementById('volet_form').submit();
    },
    toUpper(str) {
      return str.toUpperCase();
    },
  },
  async mounted() {
    if (this.$props.pay_now) {
      this.pay();
    }
  },
};
</script>
