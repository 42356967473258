<template>
  <div class="flex">
    <router-view />
  </div>
</template>

<script>
console.log(process.env);
export default {
  data() {
    return {};
  },
};
</script>
