<template>
  <div class="flex min-h-screen min-w-screen">
    <ProgressSpinner
      class="flex align-items-center justify-content-center min-h-screen"
    />
  </div>
</template>

<script>
import Connect from '@/assets/js/Connect';
import { useRoute } from 'vue-router';

export default {
  name: 'Main',
  data() {
    return {
      courses: null,
    };
  },
};
</script>
