import axios from 'axios';
const backend_url = process.env.VUE_APP_HOSTNAME;
const SUCCESS_URL = process.env.VUE_APP_SUCCESS_URL;
const CANCEL_URL = process.env.VUE_APP_CANCEL_URL;
const STATUS_URL = process.env.VUE_APP_STATUS_URL;

export default class Connect {
  constructor() {}

  get SUCCESS_URL() {
    return SUCCESS_URL;
  }

  get CANCEL_URL() {
    return CANCEL_URL;
  }

  get STATUS_URL() {
    return STATUS_URL;
  }

  async create_payment({
    amount,
    currency,
    payment_system,
    order_id,
    is_test,
  }) {
    const url = `${backend_url}/api/create_payment/`;
    try {
      const response = await axios.post(
        url,
        { amount, currency, payment_system, order_id, is_test },
        { withCredentials: false }
      );
      const isValid = response?.status === 200;
      return isValid ? response.data : { result: false };
    } catch (e) {
      console.error(e);
      return { result: false, error: e };
    }
  }

  async exec_payment(token) {
    const url = `${backend_url}/api/exec_payment/`;
    try {
      const response = await axios.post(
        url,
        { token },
        { withCredentials: false }
      );
      const isValid = response?.status === 200;
      return isValid ? response.data : { result: false };
    } catch (e) {
      console.error(e);
      return { result: false, error: e };
    }
  }

  async getVoletAcSign({
    ac_account_email,
    ac_sci_name,
    ac_amount,
    ac_currency,
    ac_order_id,
  }) {
    const url = `${backend_url}/api/volet/ac_sign/`;
    try {
      const response = await axios.post(
        url,
        { ac_account_email, ac_sci_name, ac_amount, ac_currency, ac_order_id },
        { withCredentials: false }
      );
      const isValid = response?.status === 200;
      return isValid ? response.data : { result: false };
    } catch (e) {
      console.error(e);
      return { result: false, error: e };
    }
  }
}
