<template>
  <h1>404</h1>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
