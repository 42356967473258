<template>
  <div class="flex min-h-screen min-w-screen">
    <ProgressSpinner
      class="flex align-items-center justify-content-center min-h-screen"
    />
  </div>
  <div
    v-if="isAdvPayment && ac_sign !== null"
    class="hidden"
    style="display: none"
  >
    <div>
      <PayFormAdv
        :amount="amount"
        :currency="currency"
        :transfer_id="order_id"
        :ac_sign="ac_sign"
        :unique_order_id="unique_order_id"
        :ac_account_email="ac_account_email"
        :ac_sci_name="ac_sci_name"
        :pay_now="check_pay"
      />
    </div>
  </div>
</template>

<script>
import Connect from '@/assets/js/Connect';
import PayFormAdv from '@/components/PayFormAdv';
import PayFormPaypal from '@/components/PayFormPaypal.vue';
export default {
  data() {
    return {
      amount: null,
      currency: null,
      payment_system: null,
      order_id: null, //external
      transfer_id: null,
      link: null,
      check_pay: false,
      isAdvPayment: false,
      ac_sign: null,
      unique_order_id: null,
      ac_account_email: null,
      ac_sci_name: null,
    };
  },
  components: {
    PayFormAdv,
    PayFormPaypal,
  },
  async mounted() {
    this.check_pay = true;
    this.connect = new Connect();
    this.amount = this.$route.query.amount;
    this.isTest = this.$route.query.test === '1';
    this.currency = this.$route.query.currency?.toUpperCase();
    this.payment_system = this.$route.query.type;
    this.isAdvPayment = this.$route.query.type === 'adv';
    this.order_id = this.$route.query.order_id;
    const isValid =
      this.amount && this.currency && this.payment_system && this.order_id;

    if (this.isAdvPayment || !isValid) {
      const voletAcSignRequest = {
        ac_account_email: 'info@prodaway.org',
        ac_sci_name: 'Customer Solutions LTD',
        ac_amount: this.amount,
        ac_currency: this.currency,
        ac_order_id: this.order_id + '_' + Date.now(),
      };
      this.unique_order_id = voletAcSignRequest.ac_order_id;
      this.ac_account_email = voletAcSignRequest.ac_account_email;
      this.ac_sci_name = voletAcSignRequest.ac_sci_name;
      // no need to call getVoletAcSign as SCI password not setted in account settings.
      // const acSign = await this.connect.getVoletAcSign(voletAcSignRequest);
      // this.ac_sign = acSign.ac_sign;
      this.ac_sign = 'ac_sign';

      return;
    }

    const order = await this.connect.create_payment({
      amount: this.amount,
      currency: this.currency,
      payment_system: this.payment_system,
      order_id: this.order_id,
      is_test: this.isTest,
    });
    this.link = order.result && order.data;
    window.location.href = this.link || this.connect.CANCEL_URL;
  },
};
</script>
