import { createRouter, createWebHistory } from 'vue-router';

import MainPage from '@/views/MainPage';
import NotFound from '@/views/NotFound';
import PayUrl from '@/views/PayUrl';
import PayPalExec from '@/views/PayPalExec';

const routes = [
  {
    path: '/',
    name: 'Main Page',
    name_ru: 'Главная',
    component: MainPage,
    meta: {
      requiresAuth: false,
      title: 'Main Page | SendNow',
    },
  },
  {
    path: '/pay_by_url/',
    name: 'PayUrl',
    component: PayUrl,
    meta: {
      requiresAuth: false,
      pageMode: 'clean',
    },
  },
  {
    path: '/exec_paypal/',
    name: 'PayPalExec',
    component: PayPalExec,
    meta: {
      requiresAuth: false,
      pageMode: 'clean',
    },
  },

  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'Not found',
  },
];

const router = createRouter({
  history: createWebHistory(),
  // base: process.env.BASE_URL,
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : 'SendNow';
  next();
});

export default router;
